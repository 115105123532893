




























































































































import { Component, Vue } from 'vue-property-decorator'
import { themeConfig } from '@/config/themeConfig'

@Component({
  components: {
    Logo: () => import('@/components/Logo.vue')
  }
})
export default class TermsOfService extends Vue {
  links = themeConfig.app.links

  goToPreviousPage() {
    this.$router.go(-1)
  }
}
